import { Injectable } from '@angular/core';
import { ServerUrl, DataService, Constants } from '../_helpers';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

declare var Mobile: any;
declare var JSInterface: any;
declare var webkit: any;

@Injectable()
export class CommonService {

  private offCanvasTogglerParent = new Subject<any>();
  private modalCloseSubject = new Subject<any>();

  timeOutVar: any;
  timeIntervalVar: any;

  constructor(private dataservice: DataService) { }

  iframe_resize() {
    try{
      if (typeof Mobile != 'undefined' && typeof Mobile.iframe_resize == "function") {
        Mobile.iframe_resize();
      } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.iframe_resize != 'undefined') {
        webkit.messageHandlers.iframe_resize.postMessage("event from Angular Iframe");
      } else {
        window.parent.postMessage(document.getElementsByClassName('external-ref-page')[0].clientHeight, "*");
      }
      clearTimeout(this.timeOutVar);
      clearInterval(this.timeIntervalVar);
      this.timeIntervalVar = setInterval(() => {
        if (typeof Mobile != 'undefined' && typeof Mobile.iframe_resize == "function") {
          Mobile.iframe_resize();
        } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.iframe_resize != 'undefined') {
          webkit.messageHandlers.iframe_resize.postMessage("event from Angular Iframe");
        } else {
          window.parent.postMessage(document.getElementsByClassName('external-ref-page')[0].clientHeight, "*");
        }
      }, 100);
    }
    catch (e) {
      console.log(e);
    }
  }

  openMobileInstantUrl(url) {
    if (typeof JSInterface != 'undefined' && typeof JSInterface.loadInstantGameUrl == "function") {
      JSInterface.loadInstantGameUrl(url);
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.loadInstantGameUrl != 'undefined') {
      webkit.messageHandlers.loadInstantGameUrl.postMessage(url);
    }
  }

  getWindowDimensions() {
    if (typeof Mobile != 'undefined' && typeof Mobile.getWindowDimensions == "function") {
      Mobile.getWindowDimensions();
    } else if (typeof webkit != 'undefined' && typeof webkit.messageHandlers.getWindowDimensions != 'undefined') {
      webkit.messageHandlers.getWindowDimensions.postMessage("event from Angular Iframe");
    } else {
      window.parent.postMessage({ "msg": "windowdimensions", "class": '' }, "*");
    }
  }

}
