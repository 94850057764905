import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import { SlotService, CommonService, LoaderService } from '../_services';
import {Observable, from, of} from 'rxjs'; // Import from operator
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
                private SlotService: SlotService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const token = route.paramMap.get('token');

    // Convert the Promise to an Observable using the 'from' operator
    // @ts-ignore
    // @ts-ignore
    return from(this.SlotService.getPlayerAccountInfo(token)).pipe(
      map((response: any) => {
        // console.log("Response :: " + JSON.stringify(response));
        if (response.isValid) {
          return true; // Token is valid, allow access
        } else {
          // Token is invalid, navigate to a different page or show an error
          //this.router.navigate(['/error-page']);
          return false;
        }
      }),
      catchError((error: any) => {
        // Handle error (e.g., network issue, server error)
        console.error(error);

        // Return a value as an observable (in this case, 'of(false') to indicate that access is denied
        return of(false);
      })
    );
  }
}
