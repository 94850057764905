<style *ngIf="roomId">
  body {
    background: none;
  }
  .cobalt-theme {
    background:  none;
  }
  .button {
    padding: 15px 25px;
    font-size: 24px;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
  }

  .button:hover {background-color: #3e8e41}

  .button:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
</style>

<style *ngIf="!roomId">
  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  body {
    padding: 0;
    margin: 0;
  }

  #notfound {
    position: relative;
    height: 100vh;
    background: #f6f6f6;
  }

  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .notfound {
    max-width: 767px;
    width: 100%;
    line-height: 1.4;
    padding: 60px 40px;
    text-align: center;
    background: #fff;
    -webkit-box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
  }

  .notfound .notfound-404 {
    position: relative;
    height: 180px;
  }

  .notfound .notfound-404 h1 {
    font-family: 'Roboto', sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 165px;
    font-weight: 700;
    margin: 0px;
    color: #262626;
    text-transform: uppercase;
  }

  .notfound .notfound-404 h1>span {
    color: #00b7ff;
  }

  .notfound h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
    color: #151515;
    margin-top: 0px;
    margin-bottom: 25px;
  }


  @media only screen and (max-width: 767px) {
    .notfound h2 {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 480px) {

    .notfound .notfound-404 h1 {
      font-size: 141px;
    }
  }
</style>


<div id="notfound" *ngIf="!roomId">
  <div class="notfound">
    <div class="notfound-404">
      <h1>4<span>0</span>4</h1>
    </div>
    <h2>Sorry!! The page you requested could not found</h2>
  </div>
</div>
