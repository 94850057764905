import { Injectable, OnDestroy } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { ServerUrl, Constants } from '../_helpers';
import { DataService } from '../_helpers/data-service-handle';

@Injectable()
export class SlotService implements OnDestroy {
  private gameListObserver = new Subject<any>();
  private authUserObserver = new Subject<any>();
  private gameSessionObserver = new Subject<any>();
  private purchaseTicketResponse: any;

  static gameTime = 0;
  static gameTimeInterval: any;
  static fetchGameCounter = 0;
  isMobile = false;

  orderingTicketList = {
    gameCode: '',
    orderBy: '',
  };
  fromMyTickets = false;

  constructor(private dataservice: DataService) {}

  ngOnDestroy(): void {}

  subscribeGameList(): Observable<any> {
    return this.gameListObserver.asObservable();
  }

  subscribeAuthUser(): Observable<any> {
    return this.authUserObserver.asObservable();
  }

  getGameList = (timerType = '') => {
    this.returnGameListResponse(timerType).subscribe((response) => {
      this.gameListObserver.next(response);
    });
  };

  authUserDetails = (timerType = '') => {
    this.returnAuthUserResponse(timerType).subscribe((response) => {
      this.authUserObserver.next(response);
    });
  };

  returnGameListResponse(timerType = '') {
    // return this.dataservice.postData(ServerUrl.SLOT_GAMELIST, {  }, { withCredentials: true })
    return this.dataservice.postData(ServerUrl.SLOT_GAMELIST, {}, {}).pipe(
      map((response: any) => {
        if (typeof response.data != 'undefined') {
          return response;
        } else {
          if (response.responseCode == 1000) {
            return response;
          } else {
          }
        }
      })
    );
  }

  returnAuthUserResponse(token = '') {
    return this.dataservice
      .postData(
        ServerUrl.GET_ACCOUNT,
        { token: token },
        { withCredentials: true }
      )
      .pipe(
        map((response: any) => {
          if (typeof response.data != 'undefined') {
            return response;
          } else {
            if (response.responseCode == 1000) {
              return response;
            } else {
            }
          }
        })
      );
  }

  getGameSession(
    gameId = '',
    playerToken = '',
    account = '',
    gameCode = '',
    vendor = ''
  ) {
    return this.dataservice
      .postData(
        ServerUrl.GENERATE_SESSION_GAME,
        {
          gameId: gameId,
          playerToken: playerToken,
          account: account,
          gameCode: gameCode,
          vendor: vendor,
        },
        { withCredentials: true }
      )
      .pipe(
        map((response: any) => {
          if (typeof response.data != 'undefined') {
            return response;
          } else {
            if (response.responseCode == 1000) {
              return response;
            } else {
            }
          }
        })
      );
  }
}
