import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  constructor(private http: HttpClient) { }

  getData(url, resource, headers) {
    return this.http.get<any>(url, headers);
  }

  postData(url, resources, headers) {
    return this.http.post<any>(url, resources, headers);
  }

  updateData(url, resources, headers) {
    return this.http.patch<any>(url, resources, headers);
  }

  removeData(url, resources, headers) {
    return this.http.delete<any>(url, headers);
  }

}