<!--<ngx-ui-loader-->
<!--  [fgsColor]="loader.config.fgsColor"-->
<!--  [fgsPosition]="loader.config.fgsPosition"-->
<!--  [fgsSize]="loader.config.fgsSize"-->
<!--  [fgsType]="loader.config.fgsType"-->
<!--  [bgsColor]="loader.config.bgsColor"-->
<!--  [bgsOpacity]="loader.config.bgsOpacity"-->
<!--  [bgsPosition]="loader.config.bgsPosition"-->
<!--  [bgsSize]="loader.config.bgsSize"-->
<!--  [bgsType]="loader.config.bgsType"-->
<!--  [gap]="loader.config.gap"-->
<!--  [logoPosition]="loader.config.logoPosition"-->
<!--  [logoSize]="loader.config.logoSize"-->
<!--  [logoUrl]="loader.config.logoUrl"-->
<!--  [overlayColor]="loader.config.overlayColor"-->
<!--  [pbColor]="loader.config.pbColor"-->
<!--  [pbDirection]="loader.config.pbDirection"-->
<!--  [pbThickness]="loader.config.pbThickness"-->
<!--  [hasProgressBar]="loader.config.hasProgressBar"-->
<!--  [text]="loader.config.text"-->
<!--  [textColor]="loader.config.textColor"-->
<!--  [textPosition]="loader.config.textPosition"-->
<!--&gt;</ngx-ui-loader>-->

<div id="headerSection">

  <header class="main-header sticky-top navbar-light" id="gaming__header">

    <div class="row main-header__container header-bar" [ngClass]="{
        'launch-game' : (pageType == 'GAMEPLAY'),
        'list-game'  : (pageType == 'GAMELIST')
       }">

      <div class="col-6 col-md-2 col-lg-2 main-header__logo d-flex align-items-center justify-content-start px-0 px-md-2 px-lg-3">
        <button class="hamburger d-md-none icon-sports-selected d-none" type="button" rel="hamburger" data-target="#sidebar-mobile-menu-right"></button>
        <a href="{{ this.homeURL }}/{{ this.currTokenVal }}" class="d-block logo_img">
          <img src="/assets/images/logo.png" class="main-header__logo__img custom-logo img-fluid" alt="Logo" id="site-logo">
        </a>
      </div>

<!--      <nav class="col-12 col-md-6 nav-list order-1 order-md-0 p-0">-->
<!--        <ul id="primary-menu" class="clearfix nav-list__list w-100">-->
<!--          <li class="menu-item nav-list__item ps-lg-0">-->
<!--            <a data-home="true" href="{{ this.webDomain }}/sports/home" id="sports_item_link" class=" nav-list__item__link menu_item  home-link">Sportsbook</a>-->
<!--          </li>-->
<!--          <li class="menu-item nav-list__item">-->
<!--            <a data-home="true" href="{{ this.webDomain }}/sports/live-betting" id="live_item_link" class=" nav-list__item__link ">Live Betting</a>-->
<!--          </li>-->
<!--          <li class="menu-item nav-list__item">-->
<!--            <a href="{{ this.webDomain }}/casino" class=" nav-list__item__link menu_item active">Casino</a>-->
<!--          </li>-->
<!--          <li class="menu-item nav-list__item">-->
<!--            <a href="{{ this.webDomain }}/casino/live-casino" class=" nav-list__item__link menu_item ">Live Casino</a>-->
<!--          </li>-->
<!--          <li class="menu-item nav-list__item">-->
<!--            <a href="{{ this.webDomain }}/casino/lotto" class=" nav-list__item__link menu_item ">Lotto</a>-->
<!--          </li>-->
<!--          <li class="menu-item nav-list__item">-->
<!--            <a href="{{ this.webDomain }}/racebook" class=" nav-list__item__link menu_item ">Racing</a>-->
<!--          </li>-->
<!--        </ul>-->
<!--        <ul id="headerLaunchGame" class="clearfix nav-list__list w-100" *ngIf="(pageType == 'GAMEPLAY')">-->
<!--          <li class="menu-item nav-list__item active">-->
<!--            <a href="javascript:void(0)" class="nav-list__item__link menu_item" (click)="switchMode('GAMELIST')">-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left custom-svg-color" viewBox="0 0 16 16">-->
<!--                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>-->
<!--              </svg>-->
<!--              Back-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </nav>-->

      <nav class="col-12 col-md-6 nav-list order-1 order-md-0 p-0" style="margin: auto 0;">

        <ul class="nav main-casino__tabs__nav" style="" *ngIf="pageType == 'GAMELIST'">

          <li class="menu-item nav-list__item " >
            <a href="javascript:void(0)" class="nav-list__item__link menu_item" (click)="backClicked()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left custom-svg-color" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>
              </svg>
              Back
            </a>
          </li>

          <li *ngFor="let tab of tabs; let k = index" [ngClass]="{'active': filter == tab}"
              class="nav-item main-casino__tabs__nav__item category_tab" (click)="changeFilter(tab)">
            <a class="nav-link categoryNameTab pl-0" >{{tab|titlecase}}</a>
          </li>

        </ul>

        <ul class="nav main-casino__tabs__nav" *ngIf="pageType == 'GAMEPLAY'" >
          <li class="menu-item nav-list__item "  >
            <a href="javascript:void(0)" class="nav-list__item__link menu_item" (click)="switchMode('GAMELIST')">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left custom-svg-color" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>
              </svg>
              Close
            </a>
          </li>
        </ul>


      </nav>
      <div class="col-6 col-md-4 d-flex pe-md-2 pe-lg-3 justify-content-end">

        <div class="btn-group me-md-2 me-xl-4">
          <button class="gaming-dropdown dropdown-toggle js-open-mobile user-account-drop" type="button" data-bs-toggle="collapse" data-bs-target="#cust-dropdown" aria-expanded="false" aria-controls="cust-dropdown">
            <p class="gaming-dropdown__name">{{ this.userData?.info?.accountInfo?.account }}</p>
            <p class="gaming-dropdown__balance amountAvailable" id="availableBalance">${{ this.userData?.info?.balanceInfo?.balance | number : '1.2-2' }}</p>
          </button>
        </div>
      </div>

    </div>

  </header>
  <section class="main-header__sub-header d-block" *ngIf="pageType == 'GAMELIST'">
    <div class="row main-header__sub-container mx-auto">
      <div class="col-12 col-lg-6 d-flex pl-2 pr-0">


        <div *ngIf="pageType == 'GAMELIST'" class="d-flex align-items-center col-12 col-lg-4 p-0 pl-lg-2" >
          <form action="/sports/search-team" method="get" class="search-desktop" id="searchsportsbook">
            <div class="input-group search-desktop__input-group">
              <div class="input-group-prepend me-1 justify-content-center d-flex">
                <span class="input-group-text icon-search search-desktop__input-group__icon-search rounded-0 d-flex align-items-center border-right-0" style="padding:0;font-size: 20px;"></span>
              </div>
              <input [(ngModel)]="searchText"  class="sportsbook__sidebar-search-input search-team-input search-desktop__input-group__search form-control rounded-0 border-left-0 d-block" name="teamName" placeholder="Search MyBookie..." type="text" maxlength="60" minlength="3" autocomplete="off" id="search_myb" style="font-size: 14px;">
            </div>
          </form>
        </div>

<!--        <nav class="d-flex align-items-center nav-list">-->
<!--          <ul id="primary-menu" class="clearfix nav-list__list w-100">-->
<!--            <li class="menu-item nav-list__sub-item">-->
<!--              <a href="{{ this.webDomain }}/atm/cashier/deposit" class=" nav-list__sub-item__link ">Cashier</a>-->
<!--            </li>-->
<!--            <li class="menu-item nav-list__sub-item">-->
<!--              <a href="{{ this.webDomain }}/refer_friend" class=" nav-list__sub-item__link ">Refer Your Friends</a>-->
<!--            </li>-->
<!--            <li class="menu-item nav-list__sub-item">-->
<!--              <a href="{{ this.webDomain }}/promotions-and-bonuses" class=" nav-list__sub-item__link ">Bonuses</a>-->
<!--            </li>-->
<!--            <li class="menu-item nav-list__sub-item">-->
<!--              <a href="{{ this.webDomain }}/contests" class=" nav-list__sub-item__link ">Contests</a>-->
<!--            </li>-->
<!--            <li class="menu-item nav-list__sub-item">-->
<!--              <a href="{{ this.webDomain }}/vip" class=" nav-list__sub-item__link ">VIP</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </nav>-->
      </div>
<!--      <div class="col-md-6 col-lg-6 d-flex align-items-center justify-content-end sub-header-right-side pl-0 pr-md-2 pr-lg-3">-->
<!--        <div class="free-play-and-crypto text-end">-->
<!--          <p class="m-0">Free Play:-->
<!--            <span class="free-play-and-crypto__free-play-amount freePlayAvailable">$0.00</span>-->
<!--          </p>-->
<!--          <p class="m-0">-->
<!--            <i class="icon-tooltip crypto-balance-info" id="crypto-tool">-->
<!--                            <span class="crypto-balance-info__balance-info-content text-capitalize" style="max-width: 270px;">-->
<!--                                Make your first crypto deposit to convert this reward to cash.-->
<!--                                <a href="https://help.mybookie.ag/knowledge-base-2/bitcoin/mybookie-crypto-reward/" class="crypto-balance-learn-more">Learn more</a>-->
<!--                            </span>-->
<!--            </i>-->
<!--            Crypto Reward: <span class="free-play-and-crypto__crypto-amount">0.00</span>-->
<!--          </p>-->
<!--        </div>-->
<!--        <div class="help-center text-center">-->
<!--          <a href="javascript:void(0)" class="open_chat help-center__link" rel="nofollow">Help</a>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </section>

</div>

<div class="main-site-content container-fluid p-0 brand-1">


<div id="gameplay" *ngIf="pageType == 'GAMEPLAY'" class="container-fluid py-3 main-casino" >
  <div class="row" style="display: none;">
    <div class="col-12 d-flex top-sliders">
      <div class="col-10 d-flex">
        <div class="mx-0 col-12 col-md-4 pe-2 mb-4 mb-md-0">
          <h6 class="accent-color ms-2 ms-md-0 launch-title">RECENTLY PLAYED</h6>
          <div class="carousel-container pt-3 launch-game">
            <div class="carousel slide w-100" data-bs-ride="carousel">
              <div class="carousel-inner carousel-container-launch top-sliders-casino" role="listbox">

                <div class="swiper swiper-1">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/010221/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/0A7373/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/B7BF99/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/EDAA25/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/C43302/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/146152/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                  </div>

                  <div class="swiper-pagination"></div>
                  <!-- If we need navigation buttons -->
                  <div class="swiper-button-prev"></div>
                  <div class="swiper-button-next"></div>
                  <!-- If we need scrollbar -->
                  <div class="swiper-scrollbar"></div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="mx-0 col-12 col-md-4 pe-2 mb-4 mb-md-0">
          <h6 class="accent-color ms-2 ms-md-0 launch-title">MY FAVORITES</h6>
          <div class="carousel-container pt-3 launch-game">
            <div class="carousel slide w-100" data-bs-ride="carousel">
              <div class="carousel-inner carousel-container-launch top-sliders-casino" role="listbox">

                <div class="swiper swiper-1">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/010221/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/0A7373/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/B7BF99/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/EDAA25/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/C43302/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/146152/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                  </div>

                  <div class="swiper-pagination"></div>
                  <!-- If we need navigation buttons -->
                  <div class="swiper-button-prev"></div>
                  <div class="swiper-button-next"></div>
                  <!-- If we need scrollbar -->
                  <div class="swiper-scrollbar"></div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="mx-0 col-12 col-md-4 pe-2 mb-4 mb-md-0">
          <h6 class="accent-color ms-2 ms-md-0 launch-title">MOST POPULAR</h6>
          <div class="carousel-container pt-3 launch-game">
            <div class="carousel slide w-100" data-bs-ride="carousel">
              <div class="carousel-inner carousel-container-launch top-sliders-casino" role="listbox">

                <div class="swiper swiper-1">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/010221/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/0A7373/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/B7BF99/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/EDAA25/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/C43302/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                    <div class="swiper-slide">
                      <a href="#" class="main_banner d-block button_game">
                        <picture><img src="https://placehold.co/450x253/146152/FFF" class="img-fluid launch-game w-100"></picture>
                      </a>
                    </div>
                  </div>

                  <div class="swiper-pagination"></div>
                  <!-- If we need navigation buttons -->
                  <div class="swiper-button-prev"></div>
                  <div class="swiper-button-next"></div>
                  <!-- If we need scrollbar -->
                  <div class="swiper-scrollbar"></div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2">
        <h6 class="accent-color text-uppercase">search games</h6>
        <div class="search-casino launch-game">
          <div class="col-12 ps-2 p-3">
            <div class="button-search">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-search" viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z">
                </path>
              </svg>
              <input class="input-search-casino w-75 ms-2" name="sportsbook_search_team" placeholder="Start typing…" type="text" maxlength="20">
              <div class="button-search__results d-none">

                <!-- <div class="row button-search__scroll">
                    <div class="col-12 d-flex mt-2 mb-2">
                        <div class="col-6">
                            <img src="https://placehold.co/450x253/EDAA25/FFF" alt="Game Name" title="Game Name">
                        </div>
                        <div class="col-6" style="cursor: pointer;">
                            <p class="text-wrap text-site">Hello Paris</p>
                        </div>
                    </div>
                </div>
                <div class="row button-search__scroll">
                    <div class="col-12 d-flex mt-2 mb-2">
                        <div class="col-6">
                            <img src="https://placehold.co/450x253/EDAA25/FFF" alt="Game Name" title="Game Name">
                        </div>
                        <div class="col-6" style="cursor: pointer;">
                            <p class="text-wrap text-site">Hello Paris</p>
                        </div>
                    </div>
                </div> -->

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mx-0 game_container">
    <div class="mt-3 description-game col-8 col-sm-8 col-md-2" style="display: none;">
      <div class="row pb-md-5 mt-4">
        <h5 class="description-game__game-name">{{ this.gameDetails.game.NAME }}</h5>
        <h6 class="accent-color">GAME DESCRIPTION</h6>
        <p class="description_game">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Deserunt accusantium ducimus libero unde architecto laborum? Tempora ut minus sequi quisquam natus dolorum, labore illum incidunt accusamus nam ipsa. Accusantium, dolor. Fugit corporis quaerat quos ipsum quidem eligendi libero animi natus, sequi autem alias fuga, amet, debitis eveniet assumenda. Cupiditate saepe sit, commodi accusamus dolorem obcaecati explicabo in! Neque, ab quia. Aperiam, officia similique. Nulla reiciendis ullam rerum ipsam cum veritatis illum labore maiores iusto eveniet, nisi vero dolorem atque perferendis eos corporis ad expedita mollitia? Totam quia earum sapiente accusamus.</p>

        <span class="accent-color" style="cursor: pointer;"> See More
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill custom-svg-color" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                        </svg>
                    </span>
        <div class="row description-game__metadata pb-3">
<!--          <h6 class="accent-color mb-0">FEATURES</h6>-->
<!--          <span class="text-site">FREE SPINS, BONUS ROUND, BONUS BUY</span>-->
          <div class="col-6 mb-3 mt-3">
            <h6 class="accent-color">RTP</h6>
            <span class="text-site">{{ this.gameDetails.game.defaultRtp }}%</span>
          </div>
          <div class="col-6 mb-3 mt-3">
            <h6 class="accent-color">VOLATILITY</h6>
            <span class="text-site">{{ this.gameDetails.game.volatility }}</span>
            <div *ngIf="this.gameDetails.game.volatility != ''" class="progress description-game__progress" style="height: 14px;">
              <div class="progress-bar description-game__progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 100%;">
                <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16" style="position: relative; left: 0px;">
                  <circle cx="8" cy="8" r="8"></circle>
                </svg>
              </div>
            </div>
          </div>
<!--          <div class="col-6 mb-3 mt-1">-->
<!--            <h6 class="accent-color">REELS</h6>-->
<!--            <span class="text-site">5x5</span>-->
<!--          </div>-->
          <div class="col-6 mb-3">
            <h6 class="accent-color">MIN BET</h6>
            <span class="text-site">${{ this.gameDetails.game.minBet }}</span>
          </div>
<!--          <div class="col-6 mb-3">-->
<!--            <h6 class="accent-color">PAY LINES</h6>-->
<!--            <span class="text-site">75 </span>-->
<!--          </div>-->
          <div class="col-6 mb-3">
            <h6 class="accent-color">MAX BET</h6>
            <span class="text-site">${{ this.gameDetails.game.maxBet }}</span>
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="{'px-3': isMobileDevice, 'px-5': !isMobileDevice}" class="game col-12 mt-1 {{addOnClass}}" id="gamePlayDiv" #gamePlayDiv >
      <div [ngClass]="{'ratio-mobile': isMobileDevice, 'ratio-16x9': !isMobileDevice}" class="ratio gold-wrap" id="gamePlayContainer" #gamePlayContainer>
        <div class="full-screen-btn" [ngStyle]="getFullScreenButtonStyles()" (click)="toggleFullScreen()">

<!--          <svg *ngIf="addOnClass == 'fullScreenOff'" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">-->
<!--            <path id="Icon_open-fullscreen-enter" data-name="Icon open-fullscreen-enter" d="M36,0V18l-6.75-6.75L22.5,18,18,13.5l6.75-6.75L18,0ZM13.5,18,18,22.5l-6.75,6.75L18,36H0V18l6.75,6.75Z"/>-->
<!--          </svg>-->

          <svg *ngIf="addOnClass == 'fullScreenOff'" style="float:right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path id="svg-full" d="M1.5,1a.5.5,0,0,0-.5.5v4a.5.5,0,1,1-1,0v-4A1.5,1.5,0,0,1,1.5,0h4a.5.5,0,0,1,0,1ZM10,.5a.5.5,0,0,1,.5-.5h4A1.5,1.5,0,0,1,16,1.5v4a.5.5,0,0,1-1,0v-4a.5.5,0,0,0-.5-.5h-4A.5.5,0,0,1,10,.5ZM.5,10a.5.5,0,0,1,.5.5v4a.5.5,0,0,0,.5.5h4a.5.5,0,0,1,0,1h-4A1.5,1.5,0,0,1,0,14.5v-4A.5.5,0,0,1,.5,10Zm15,0a.5.5,0,0,1,.5.5v4A1.5,1.5,0,0,1,14.5,16h-4a.5.5,0,0,1,0-1h4a.5.5,0,0,0,.5-.5v-4A.5.5,0,0,1,15.5,10Z" fill="#ff8300"/>
          </svg>

<!--          <svg *ngIf="addOnClass == 'fullScreenOn'" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">-->
<!--            <path id="Icon_open-fullscreen-exit" data-name="Icon open-fullscreen-exit" d="M31.5,0,36,4.5l-6.75,6.75L36,18H18V0l6.75,6.75ZM18,18V36l-6.75-6.75L4.5,36,0,31.5l6.75-6.75L0,18Z"/>-->
<!--          </svg>-->

          <svg *ngIf="addOnClass == 'fullScreenOn'" style="float:right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path id="Union_1" data-name="Union 1" d="M10,15.5v-4A1.5,1.5,0,0,1,11.5,10h4a.5.5,0,1,1,0,1h-4a.5.5,0,0,0-.5.5v4a.5.5,0,1,1-1,0Zm-5,0v-4a.5.5,0,0,0-.5-.5H.5a.5.5,0,1,1,0-1h4A1.5,1.5,0,0,1,6,11.5v4a.5.5,0,1,1-1,0ZM11.5,6A1.5,1.5,0,0,1,10,4.5V.5a.5.5,0,1,1,1,0v4a.5.5,0,0,0,.5.5h4a.5.5,0,1,1,0,1ZM.5,6a.5.5,0,1,1,0-1h4A.5.5,0,0,0,5,4.5V.5a.5.5,0,1,1,1,0v4A1.5,1.5,0,0,1,4.5,6Z" fill="#ff8300"/>
          </svg>


        </div>
        <iframe #gameWindow [src]="gameplay_url | safe" width="100%" frameborder="0" scrolling="auto" id="gameWindow" style="border-radius: 8px;"></iframe>
      </div>
    </div>
    <div class="col-3 actions mt-3 col-md-2" style="display: none;">
      <div class="row row_actions">
        <div class="casino-actions">
          <div class="casino-actions__title">
                            <span class="casino-actions__arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right custom-svg-color" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"></path>
                                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"></path>
                                </svg>
                            </span>
            <h6 class="mt-2 accent-color">CONTROLS</h6>
          </div>
<!--          <div class="row">-->
<!--            <div class="col-8 controls">-->
<!--              <div class="row">-->
<!--                <div class="col-12 col-md-6 col-lg-6">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="47" class="bi bi-heart custom-svg-color" fill="currentColor" viewBox="0 0 16 16" x="60" y="65">-->
<!--                    <path-->
<!--                      d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z">-->
<!--                    </path>-->
<!--                  </svg>-->
<!--                  <p class="text-site mb-0">FAVORITE</p>-->
<!--                </div>-->
<!--                <div class="col-12 col-md-6 col-lg-6 ps-md-0">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="47" class="bi bi-fullscreen custom-svg-color" viewBox="0 0 16 16">-->
<!--                    <title>Fullscreen</title>-->
<!--                    <path-->
<!--                      d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z">-->
<!--                    </path>-->
<!--                  </svg>-->
<!--                  <p class="text-site mb-0 pe-md-0 ps-md-0">FULLSCREEN</p>-->
<!--                </div>-->
<!--                <div class="col-12 col-md-6 col-lg-6">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="47" fill="currentColor" class="bi bi-exclamation-triangle custom-svg-color" viewBox="0 0 16 16">-->
<!--                    <title>Report</title>-->
<!--                    <path-->
<!--                      d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z">-->
<!--                    </path>-->
<!--                    <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"></path>-->
<!--                  </svg>-->
<!--                  <p class="text-site mb-0">REPORT</p>-->
<!--                </div>-->
<!--                <div class="col-12 col-md-6 col-lg-6 ps-md-0">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="47" class="bi bi-fullscreen custom-svg-color" viewBox="0 0 16 16">-->
<!--                    <title>Share</title>-->
<!--                    <path-->
<!--                      d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z">-->
<!--                    </path>-->
<!--                  </svg>-->
<!--                  <p class="text-site mb-0 pe-md-0 ps-md-0">SHARE</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-4">-->
<!--              <div class="row">-->
<!--                <div class="col-12">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="47" fill="currentColor" class="bi bi-clock-history custom-svg-color" viewBox="0 0 16 16">-->
<!--                    <path-->
<!--                      d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z">-->
<!--                    </path>-->
<!--                    <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"></path>-->
<!--                    <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"></path>-->
<!--                  </svg>-->
<!--                  <p class="text-site mb-0">HISTORY</p>-->
<!--                </div>-->
<!--                <div class="col-12">-->
<!--                  <a href="https://help.mybookie.ag/" target="_blank">-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="47" fill="currentColor" class="bi bi-question-diamond custom-svg-color" viewBox="0 0 16 16">-->
<!--                      <title>Help</title>-->
<!--                      <path-->
<!--                        d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z">-->
<!--                      </path>-->
<!--                      <path-->
<!--                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z">-->
<!--                      </path>-->
<!--                    </svg>-->
<!--                  </a>-->
<!--                  <p class="text-site mb-0">HELP</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="rate mb-2 d-md-flex">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="47" class="bi bi-star me-2 custom-svg-color" viewBox="0 0 16 16">-->
<!--              <title>Rate</title>-->
<!--              <path-->
<!--                d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z">-->
<!--              </path>-->
<!--            </svg>-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="47" class="bi bi-star me-2 custom-svg-color" viewBox="0 0 16 16">-->
<!--              <title>Rate</title>-->
<!--              <path-->
<!--                d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z">-->
<!--              </path>-->
<!--            </svg>-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="47" class="bi bi-star me-2 custom-svg-color" viewBox="0 0 16 16">-->
<!--              <title>Rate</title>-->
<!--              <path-->
<!--                d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z">-->
<!--              </path>-->
<!--            </svg>-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="47" class="bi bi-star me-2 custom-svg-color" viewBox="0 0 16 16">-->
<!--              <title>Rate</title>-->
<!--              <path-->
<!--                d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z">-->
<!--              </path>-->
<!--            </svg>-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="47" class="bi bi-star me-2 custom-svg-color" viewBox="0 0 16 16">-->
<!--              <title>Rate</title>-->
<!--              <path-->
<!--                d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z">-->
<!--              </path>-->
<!--            </svg>-->
<!--            <p class="text-site mb-0 mt-md-3">RATE</p>-->
<!--            <p class="text-site d-inline text-uppercase mb-0 avg mt-md-3 ms-md-2">avg 3.57</p>-->
<!--          </div>-->
        </div>
      </div>
      <div class="row casino-actions widgets-casino mt-2">


<!--        <div class="swiper swiper-2">-->
<!--          <div class="swiper-wrapper">-->
<!--            <div class="swiper-slide">-->
<!--              <div class="col-12 d-flex">-->
<!--                <div class="col-8 casino-actions__title pe-0 ps-0">-->
<!--                  <h6 class="mt-2 text-site">RECENT WINNERS</h6>-->
<!--                </div>-->
<!--                <div class="col-2 casino-actions__title">-->
<!--                                        <span>-->
<!--                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-angle mt-1" viewBox="0 0 16 16">-->
<!--                                                <path-->
<!--                                                  d="M9.828.722a.5.5 0 0 1 .354.146l4.95 4.95a.5.5 0 0 1 0 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 0 1 .16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 0 1-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 0 1 0-.707c.688-.688 1.673-.767 2.375-.72a5.922 5.922 0 0 1 1.013.16l3.134-3.133a2.772 2.772 0 0 1-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 0 1 .353-.146zm.122 2.112v-.002.002zm0-.002v.002a.5.5 0 0 1-.122.51L6.293 6.878a.5.5 0 0 1-.511.12H5.78l-.014-.004a4.507 4.507 0 0 0-.288-.076 4.922 4.922 0 0 0-.765-.116c-.422-.028-.836.008-1.175.15l5.51 5.509c.141-.34.177-.753.149-1.175a4.924 4.924 0 0 0-.192-1.054l-.004-.013v-.001a.5.5 0 0 1 .12-.512l3.536-3.535a.5.5 0 0 1 .532-.115l.096.022c.087.017.208.034.344.034.114 0 .23-.011.343-.04L9.927 2.028c-.029.113-.04.23-.04.343a1.779 1.779 0 0 0 .062.46z">-->
<!--                                                </path>-->
<!--                                            </svg>-->
<!--                                        </span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="recent_content">-->

<!--                <div class="recent_winners new_winner">-->
<!--                  <div class="row my-2">-->
<!--                    <div class="col-3 pe-0">-->
<!--                      <img src="https://cdn-images.playdigital.io/4e71044b-60c9-47c8-86f2-c8af318b03da/4e71044b-60c9-47c8-86f2-c8af318b03da.png" class="img-tint img-fluid lazy recent-img" alt="Game">-->
<!--                    </div>-->
<!--                    <div class="col-4 d-flex flex-column pe-0">-->
<!--                      <span class="text-site name text-wrap">Carlos V.</span>-->
<!--                      <b>-->
<!--                        <span class="text-site amount">$796.50</span>-->
<!--                      </b>-->
<!--                    </div>-->
<!--                    <div class="col-4 pe-0">-->
<!--                      <button class="mw-100 w-100 m-0 btn leaving-btn btn-green">PLAY NOW</button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="recent_winners">-->
<!--                  <div class="row my-2">-->
<!--                    <div class="col-3 pe-0">-->
<!--                      <img src="https://cdn-images.playdigital.io/4e71044b-60c9-47c8-86f2-c8af318b03da/4e71044b-60c9-47c8-86f2-c8af318b03da.png" class="img-tint img-fluid lazy recent-img" alt="Game">-->
<!--                    </div>-->
<!--                    <div class="col-4 d-flex flex-column pe-0">-->
<!--                      <span class="text-site name text-wrap">Carlos V.</span>-->
<!--                      <b>-->
<!--                        <span class="text-site amount">$796.50</span>-->
<!--                      </b>-->
<!--                    </div>-->
<!--                    <div class="col-4 pe-0">-->
<!--                      <button class="mw-100 w-100 m-0 btn leaving-btn btn-green">PLAY NOW</button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="recent_winners">-->
<!--                  <div class="row my-2">-->
<!--                    <div class="col-3 pe-0">-->
<!--                      <img src="https://cdn-images.playdigital.io/4e71044b-60c9-47c8-86f2-c8af318b03da/4e71044b-60c9-47c8-86f2-c8af318b03da.png" class="img-tint img-fluid lazy recent-img" alt="Game">-->
<!--                    </div>-->
<!--                    <div class="col-4 d-flex flex-column pe-0">-->
<!--                      <span class="text-site name text-wrap">Carlos V.</span>-->
<!--                      <b>-->
<!--                        <span class="text-site amount">$796.50</span>-->
<!--                      </b>-->
<!--                    </div>-->
<!--                    <div class="col-4 pe-0">-->
<!--                      <button class="mw-100 w-100 m-0 btn leaving-btn btn-green">PLAY NOW</button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->


<!--              </div>-->
<!--            </div>-->
<!--            <div class="swiper-slide">-->
<!--              <div class="col-12 d-flex">-->
<!--                <div class="col-8 casino-actions__title pe-0 ps-0">-->
<!--                  <h6 class="mt-2 text-site">FEATURED</h6>-->
<!--                </div>-->
<!--                <div class="col-2 casino-actions__title">-->
<!--                                        <span>-->
<!--                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-angle mt-1" viewBox="0 0 16 16">-->
<!--                                                <path-->
<!--                                                  d="M9.828.722a.5.5 0 0 1 .354.146l4.95 4.95a.5.5 0 0 1 0 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 0 1 .16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 0 1-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 0 1 0-.707c.688-.688 1.673-.767 2.375-.72a5.922 5.922 0 0 1 1.013.16l3.134-3.133a2.772 2.772 0 0 1-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 0 1 .353-.146zm.122 2.112v-.002.002zm0-.002v.002a.5.5 0 0 1-.122.51L6.293 6.878a.5.5 0 0 1-.511.12H5.78l-.014-.004a4.507 4.507 0 0 0-.288-.076 4.922 4.922 0 0 0-.765-.116c-.422-.028-.836.008-1.175.15l5.51 5.509c.141-.34.177-.753.149-1.175a4.924 4.924 0 0 0-.192-1.054l-.004-.013v-.001a.5.5 0 0 1 .12-.512l3.536-3.535a.5.5 0 0 1 .532-.115l.096.022c.087.017.208.034.344.034.114 0 .23-.011.343-.04L9.927 2.028c-.029.113-.04.23-.04.343a1.779 1.779 0 0 0 .062.46z">-->
<!--                                                </path>-->
<!--                                            </svg>-->
<!--                                        </span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="mt-3">-->
<!--                <img src="https://cdn.imagez.io/1/0-RMtUkxTL-mb_big_winner_treasure_snipes_casino_slider_770x400_play_now.jpg" alt="" class="cursor-pointer">-->
<!--                <img src="https://cdn.imagez.io/1/0-X35q5kIR-mb_big_winners_war_hogs_hellaways_sportsbook_770x400_play_now.jpg" alt="" class="cursor-pointer">-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="swiper-slide">-->
<!--              <div class="col-12 d-flex">-->
<!--                <div class="col-8 casino-actions__title pe-0 ps-0">-->
<!--                  <h6 class="mt-2 text-site">LIVE JACKPOT</h6>-->
<!--                </div>-->
<!--                <div class="col-2 casino-actions__title">-->
<!--                                        <span>-->
<!--                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-angle mt-1" viewBox="0 0 16 16">-->
<!--                                                <path-->
<!--                                                  d="M9.828.722a.5.5 0 0 1 .354.146l4.95 4.95a.5.5 0 0 1 0 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 0 1 .16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 0 1-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 0 1 0-.707c.688-.688 1.673-.767 2.375-.72a5.922 5.922 0 0 1 1.013.16l3.134-3.133a2.772 2.772 0 0 1-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 0 1 .353-.146zm.122 2.112v-.002.002zm0-.002v.002a.5.5 0 0 1-.122.51L6.293 6.878a.5.5 0 0 1-.511.12H5.78l-.014-.004a4.507 4.507 0 0 0-.288-.076 4.922 4.922 0 0 0-.765-.116c-.422-.028-.836.008-1.175.15l5.51 5.509c.141-.34.177-.753.149-1.175a4.924 4.924 0 0 0-.192-1.054l-.004-.013v-.001a.5.5 0 0 1 .12-.512l3.536-3.535a.5.5 0 0 1 .532-.115l.096.022c.087.017.208.034.344.034.114 0 .23-.011.343-.04L9.927 2.028c-.029.113-.04.23-.04.343a1.779 1.779 0 0 0 .062.46z">-->
<!--                                                </path>-->
<!--                                            </svg>-->
<!--                                        </span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="mt-3">-->
<!--                <div class="mt-4 mb-3 jackpot" data-v-6784f944="">-->
<!--                  <img src="https://imagez.io/api/imageDispatch/YSf8B0fW/desktop" alt="banner" data-v-6784f944="">-->
<!--                  <h6 class="text-site mt-4 justify-content-center d-flex counter" akhi="0" data-v-6784f944="">$0</h6>-->
<!--                  <button class="mw-100 w-100 m-0 btn leaving-btn btn-gold mt-3" data-v-6784f944="">HIGH ROLLERS</button>-->
<!--                  <button class="mw-100 w-100 m-0 btn leaving-btn btn-silver mt-3" data-v-6784f944="">REGULAR STAKES</button>-->
<!--                  <button class="mw-100 w-100 m-0 btn leaving-btn btn-bronze mt-3" data-v-6784f944="">LOW BALLERS</button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="swiper-pagination"></div>-->
<!--          &lt;!&ndash; If we need navigation buttons &ndash;&gt;-->
<!--          <div class="swiper-button-prev"></div>-->
<!--          <div class="swiper-button-next"></div>-->
<!--          &lt;!&ndash; If we need scrollbar &ndash;&gt;-->
<!--          <div class="swiper-scrollbar"></div>-->

<!--        </div>-->


      </div>
    </div>



    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="speed-uo" aria-hidden="true" id="newTabModal">
      <div class="modal-dialog site-modal-box modal-dialog-centered">
        <div class="modal-content site-modal">
          <div class="modal-header site-modal__header">
            <h5 class="modal-title site-modal__title w-100 position-relative m-0"> OPEN GAME </h5>
          </div>
          <div class="site-modal__body">
            <p class="text-center"> Launching the game in a new tab/window will end any game session in progress in the current tab/window. </p>
            <p class="text-center"><b>Are you sure you want to proceed?</b></p>
          </div>
          <div class="modal-footer site-modal__footer d-flex justify-content-center">
            <input type="button" class="btn btn-gray col-6" data-bs-dismiss="modal" value="CANCEL">
            <input type="button" class="btn btn-green me-1 col-6" data-bs-dismiss="modal" value="YES">
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="speed-uo" aria-hidden="true" id="historyModal">
      <div class="modal-dialog site-modal-box modal-dialog-centered">
        <div class="modal-content site-modal share-game history-casino">
          <div class="modal-header site-modal__header">
            <h5 class="modal-title site-modal__title w-100 position-relative m-0 accent-color text-uppercase"> TRANSACTION HISTORY
              <button type="button" class="site-modal__close text-site" data-bs-dismiss="modal">
                <span aria-hidden="true">×</span>
              </button>
            </h5>
          </div>
          <div class="row mx-0 history-casino__nav_container">
            <div class="col-12 ps-lg-0 col-lg-10 pb-3">
              <nav class="history-casino__navigation">
                <div class="nav nav-home" role="tablist">
                  <a class="text-uppercase nav-item nav-link casino-tab ms-3 active" id="casino-tab" data-bs-toggle="tab" href="#casino" role="tab" aria-controls="casino" aria-selected="false"> Casino </a>
                  <a class="text-uppercase nav-item nav-link casino-live-tab" id="casino-live-tab" data-bs-toggle="tab" href="#casinoLive" role="tab" aria-controls="casinoLive"  aria-selected="false"> Live Casino </a>
                </div>
              </nav>
            </div>
          </div>
          <div class="site-modal__body container pt-1">
            <h6 class="site-text justify-content-center d-flex">No Transactions Today</h6>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="speed-uo" aria-hidden="true" id="leaveHistory">
      <div class="modal-dialog site-modal-box modal-dialog-centered">
        <div class="modal-content site-modal share-game history_casino">
          <div class="modal-header site-modal__header">
            <h5 class="modal-title site-modal__title w-100 position-relative m-0"> LEAVE CASINO </h5>
          </div>
          <div class="site-modal__body">
            <p class="text-center"> You will leave the current game. </p>
            <p class="text-center"><b>Do you want to continue?</b></p>
          </div>
          <div class="modal-footer site-modal__footer d-flex justify-content-center">
            <input type="button" class="btn btn-green me-1 col-6" data-bs-dismiss="modal" value="YES">
            <input type="button" class="btn btn-gray col-6" data-bs-dismiss="modal" value="NO">
          </div>
        </div>
      </div>
    </div>

<!--    <div class="justify-content-center row mt-3 mx-0 regular-games">-->
<!--      <div class="col-lg-3 col-md-4 col-sm-6 col-6 mb-3 main-casino__game" rel="favGameCont">-->
<!--        <div class="container-fluid d-flex justify-content-center">-->
<!--          <div class="container-fluid d-flex justify-content-center" data-v-77a22c80="">-->
<!--            <div class="lds-grid" data-v-77a22c80="">-->
<!--              <div data-v-77a22c80=""></div>-->
<!--              <div data-v-77a22c80=""></div>-->
<!--              <div data-v-77a22c80=""></div>-->
<!--              <div data-v-77a22c80=""></div>-->
<!--              <div data-v-77a22c80=""></div>-->
<!--              <div data-v-77a22c80=""></div>-->
<!--              <div data-v-77a22c80=""></div>-->
<!--              <div data-v-77a22c80=""></div>-->
<!--              <div data-v-77a22c80=""></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</div>

<div id="gamelist" *ngIf="pageType == 'GAMELIST'">

  <div class="row mx-0 position-relative d-none">
    <div class="d-none d-md-block carousel-container pe-0 col-md-3">
      <div class="carousel slide w-100">
        <a id="slider_join_trk_undefined" href="#" class="main_banner d-block lobby_game" data-game="" data-name="" data-recently-played="">
          <picture>
            <img class="img-fluid w-100 rounded-8" alt="game banner"  src="/assets/images/banner1.jpg">
          </picture>
        </a>
      </div>
    </div>
    <div class="d-none d-md-block col-md-9 col-12 carousel-container pb-0">
      <div class="carousel slide w-100">

        <div class="swiper swiper-4">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <a href="#" class="main_banner d-block lobby_game">
                <picture><img src="/assets/images/banner2.jpg" class="img-fluid rounded-8 w-100"></picture>
              </a>
            </div>
            <div class="swiper-slide">
              <a href="#" class="main_banner d-block lobby_game">
                <picture><img src="/assets/images/banner3.jpg" class="img-fluid rounded-8 w-100"></picture>
              </a>
            </div>
            <div class="swiper-slide">
              <a href="#" class="main_banner d-block lobby_game">
                <picture><img src="/assets/images/banner4.jpg" class="img-fluid rounded-8 w-100"></picture>
              </a>
            </div>
            <div class="swiper-slide">
              <a href="#" class="main_banner d-block lobby_game">
                <picture><img src="/assets/images/banner1.jpg" class="img-fluid rounded-8 w-100"></picture>
              </a>
            </div>

          </div>

          <div class="swiper-pagination"></div>
          <!-- If we need navigation buttons -->
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
          <!-- If we need scrollbar -->
          <div class="swiper-scrollbar"></div>

        </div>

      </div>
    </div>
  </div>
  <div class="container-fluid py-3 main-casino">
    <div data-category="1" class="main-casino__tabs">

    </div>
    <div class="row">
      <div class="col-12 p-0 order-2 order-md-1 col-lg-12">
        <div id="casino-wrapper" class="">
          <div id="game-show"></div>
          <div class="w-100 casino-tab tab-content container-fluid">
            <div class="row tab-pane fade show active games-container" id="game-casino-container">
              <div class="tab-Featured Games row mt-3 mx-0 regular-games gold-wrap-list">


                <ng-container *ngFor="let data of gameData | filter:searchText | orderBy:'gameName':sortCriteria | tabFilter:filter | slice:0:30*paginationMultiplier; let i = index">
                <div [style.display]="flex" class="col-lg-2 col-md-4 col-sm-6 col-6 mb-3 main-casino__game" rel="favGameCont" data-id="">
                  <figure>
                    <a [routerLink]="" (click)="generate_session(data.vendor+'-'+data.id,data.id,data)" class="open-game" data-game-url="{{ data.game.IMAGEURL }}">
                      <img class="img-tint img-fluid lazy" alt="{{ data.game.NAME }}" [lazyLoad]="data.game.IMAGEURL" [defaultImage]="defaultImage">
                    </a>
                    <figcaption>
                      <div class="p-3">
                        <span class="main-casino__game__name d-block">{{ data.game.NAME }}</span>
                        <a [routerLink]="" (click)="generate_session(data.vendor+'-'+data.id,data.id,data)" class="main-casino__game__play-now open-game">
                          <i class="icon-play-button"></i>
                        </a>
                        <div class="gameFav main-casino__game__favorite mt-auto" data-game-id="{{ data.game.id }}" data-category="{{ data?.displayType }}" data-game-name="" data-game-path="" data-is-iphone="false" title="Add To Favorites">
                          <i class="icon-favorite-deselected" rel=""></i>
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                </div>
                </ng-container>

                <div *ngIf="isShowMoreVisible()" style="margin:auto;width:auto">
                  <button mat-button class="popup-ok-button m-auto" (click)="showMore()">Show more</button>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="col-12 col-lg-4 order-1 order-md-2">-->
<!--        <div>-->
<!--          <div id="bonusesContainer"></div>-->
<!--          <div class="mt-3 main-casino__right-rail d-none d-md-block">-->
<!--            <div class="main-casino__right-rail__featured-game">-->
<!--              <a href="#" class="d-block mb-3">-->
<!--                <img alt="game" class="img-fluid" style="border-radius: 8px;" src="https://placehold.co/610x424/C43302/FFF">-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>

</div>

<div class="site-footer __copyright pb-5">
  <div class="container px-0">
    <div class="row mx-0">
      <div class="col-md-4 text-start d-flex flex-column p-0 order-1 order-md-0 align-items-center align-items-md-start mb-4">
<!--        <img src="https://cdnbannertool-51b1.kxcdn.com/1/0-UlB9VIiJ-logo_white.png" alt="footer logo" class="img-fluid" width="154">-->
        <p class="m-0 pb-3 pt-2 text-white footer-copy-text">© Copyright 2023 MyBookie</p>
      </div>
      <div class="col-md-8 d-flex flex-column p-0">
        <div class="d-flex site-footer__copyright__social justify-content-end align-items-center flex-column flex-md-row">
<!--          <div>-->
<!--            <span class="me-md-4">FOLLOW MYBOOKIE ON SOCIAL MEDIA:</span>-->
<!--          </div>-->
<!--          <div class="d-flex pt-3 pt-md-0">-->
<!--                            <span class="icon-box">-->
<!--                                <a href="https://www.instagram.com/realmybookie/?hl=en_" title="Instagram" aria-label="Instagram" target="_blank" rel="noopener noreferrer">-->
<!--                                    <i class="icon-instagram"></i>-->
<!--                                </a>-->
<!--                            </span>-->
<!--            <span class="icon-box mx-3">-->
<!--                                <a href="https://www.youtube.com/c/MyBookieSportsbook" title="Youtube" aria-label="Youtube" target="_blank" rel="noopener noreferrer">-->
<!--                                     <i class="icon-youtube"></i>-->
<!--                                </a>-->
<!--                            </span>-->
<!--            <span class="icon-box">-->
<!--                                <a href="https://twitter.com/mybookie" title="Twitter" aria-label="Twitter" target="_blank" rel="noopener noreferrer">-->
<!--                                    <i class="icon-twitter"></i>-->
<!--                                </a>-->
<!--                            </span>-->
<!--            <span class="icon-box mx-3">-->
<!--                                <a href="https://www.twitch.tv/mybookiemadden" title="twitch" aria-label="twitch" target="_blank" rel="noopener noreferrer">-->
<!--                                    <i class="icon-twitch"></i>-->
<!--                                </a>-->
<!--                            </span>-->
<!--          </div>-->
        </div>
        <!-- <div class="d-flex site-footer__copyright__links justify-content-end py-3 text-center flex-column flex-md-row">
          <a href="https://dash.urchat.info/kb/300/3fad7612-4fdb-4a18-94f5-d9f7a1f1015b/a/c5b48e64-a41a-43c8-b9fc-bc6d64da5d58/mybookie-ag-privacy-policy" target="_blank" class="me-md-3 mb-3 mb-md-0" rel="noopener noreferrer">
            Privacy Policy
          </a>
          <a href="https://dash.urchat.info/kb/300/3fad7612-4fdb-4a18-94f5-d9f7a1f1015b/c/06909ff4-4b2d-4032-a29e-265c3a16013c/T%26amp%3bCs" target="_blank" class="mx-md-3 mb-3 mb-md-0" rel="noopener noreferrer">
            Terms and Conditions
          </a>
          <a href="javascript:void(0)" class="ms-md-3 mb-3 mb-md-0 open_chat" rel="noopener noreferrer">
            Help
          </a>
          <a href="https://www.ncpgambling.org/" target="_blank" class="ms-md-3 mb-3 mb-md-0" rel="noopener noreferrer">
            Responsible Gaming
          </a>
        </div> -->
      </div>
    </div>
  </div>
</div>

</div>



