import { RouterModule, Routes, ActivatedRouteSnapshot } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { routing } from './app.routing';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpErrorInterceptor, DEFAULT_TIMEOUT } from './_interceptors';
import { SlotService, CommonService, LoaderService } from './_services';
import { AuthGuard } from './_guards/index';
import { AppComponent, SafePipe } from './app.component';
import { WebComponent } from "./web";
import { NgSearchFilterModule } from 'ng-search-filter';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
  NgxUiLoaderRouterModule,
  NgxUiLoaderHttpModule
} from 'ngx-ui-loader';
import {FormsModule} from '@angular/forms';
import { OrderByPipe } from './_helpers/OrderByPipe';
import { TabFilterPipe } from './_helpers/TabFilterPipe';
import {MatDialogModule} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PopupComponent } from './popup/popup.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#f5efef',
  bgsOpacity: 1,
  bgsPosition: "bottom-right",
  bgsSize: 80,
  bgsType: "ball-scale-multiple",
  blur: 15,
  delay: 0,
  fastFadeOut: true,
  fgsColor: "#f8f8fb",
  fgsPosition: "center-center",
  fgsSize: 60,
  fgsType: "ball-spin-clockwise",
  gap: 60,
  logoPosition: "center-center",
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: "master",
  overlayBorderRadius: '0',
  overlayColor: "rgba(13,36,99,0.8)",
  pbColor: "#cdd0dc",
  pbDirection: "ltr",
  pbThickness: 7,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: "center-center",
  maxTime: -1,
  minTime: 200
};

@NgModule({
  declarations: [
    AppComponent,
    SafePipe,
    WebComponent,
    OrderByPipe,
    TabFilterPipe,
    PopupComponent
  ],
  imports: [
    HttpClientModule,
    RouterModule.forRoot([]),
    BrowserModule,
    BrowserAnimationsModule,
    NgSearchFilterModule,
    LazyLoadImageModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    routing,
    FormsModule,
    MatDialogModule,
    MatButtonModule
  ],
  providers: [
    CommonService,
    SlotService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_TIMEOUT,
      useValue: 25000
    },
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
