import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, EMPTY, empty, NEVER, TimeoutError, of } from 'rxjs';
import { retry, catchError, map, timeout } from 'rxjs/operators';
import { Injectable, InjectionToken, Inject } from '@angular/core';
import { Router } from '@angular/router';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
  providedIn: 'root'
})

export class HttpErrorInterceptor implements HttpInterceptor {

  static apiCounter = 0;

  constructor(
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private myRoute: Router,
  ) { }

  getOnlineStatus() {
    return navigator.onLine;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.getOnlineStatus()) {
      if (!/assets[/]i18n/.test(request.url)) {
        alert('Internet is not connected. Please check your network connection.');
        // alert(this.translate.instant('common.netconn_err'));
      }
      return EMPTY;
      // return NEVER;
      // return Observable.create(empty);
      // return Observable.throw(new HttpErrorResponse({ error: 'Internet is required.' }));
    }
    this.showLoader();
    const timeoutValue = request.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);
    return next.handle(request)
      .pipe(
        retry(0),
        timeout(timeoutValueNumeric),
        map((ev: HttpEvent<any>) => {
          if (ev instanceof HttpResponse) {
            this.hideLoader();
            if (("responseCode" in ev.body && (ev.body.responseCode == 5001 || ev.body.responseCode == 102 || ev.body.responseCode == 1021 || ev.body.responseCode == 203 || ev.body.responseCode == 118))
              || ("data" in ev.body && "responseData" in ev.body.data && (ev.body.data.responseData.statusCode == 102))) {
              // alert(ev.body.responseMessage);
              // this.myRoute.navigate(['/logout']);
              // location.href = '/logout';
            }
            return ev;
          }
          // return ev;
        }),
        catchError((error: HttpErrorResponse) => {
          this.hideLoader();
          let errorMessage = '';
          if (error instanceof TimeoutError) {
            errorMessage = "Timeout";
            // return of<any>({'success': false, 'responseCode': 12345, 'responseMessage': 'Server Timeout' });
          } else if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = "Communication Failed";
          } else {
            // server-side error
            errorMessage = "Server Error";
          }
          // window.alert(errorMessage);
          return throwError(errorMessage);
        })
      )
  }

  private showLoader(): void {
    ++HttpErrorInterceptor.apiCounter;

  }

  private hideLoader(): void {
    --HttpErrorInterceptor.apiCounter;
    if (HttpErrorInterceptor.apiCounter == 0) {

    }
  }

}
