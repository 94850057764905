import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  
  constructor(@Inject(MAT_DIALOG_DATA) public data: { title:string, message: string }) {}


  ngOnInit(): void {
  }

}
