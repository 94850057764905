import { environment } from 'src/environments/environment';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  HostListener,
  Pipe,
  PipeTransform,
  Renderer2,
} from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivationEnd, ActivatedRoute } from '@angular/router';
import { filter, first, delay, take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { SlotService, CommonService, LoaderService } from './_services';
import { Constants } from './_helpers';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { NgSearchFilterService } from 'ng-search-filter';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PopupService } from './popup/popup.service';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(gameplay_url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(gameplay_url);
  }
}

interface FsDocument extends HTMLDocument {
  mozFullScreenElement: () => void;
  webkitFullscreenElement: () => void;
  msFullscreenElement?: () => void;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen: () => void;
}

declare function reloadSwiperSlider(): any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  screenChange(event) {
    const fsDoc = <FsDocument>document;
    const gameContainer = this.gamePlayContainer.nativeElement;
    if (
      fsDoc.fullscreenElement ||
      fsDoc.mozFullScreenElement ||
      fsDoc.webkitFullscreenElement ||
      fsDoc.msFullscreenElement
    ) {
      this.addOnClass = 'fullScreenOn';
      gameContainer.classList.add('fullscreen');
      //this.htmlGameResizer();
    } else {
      this.addOnClass = 'fullScreenOff';
      gameContainer.classList.remove('fullscreen');
      //this.htmlGameResizer();
    }
  }

  @ViewChild('gamePlayDiv', { static: false }) gamePlayDiv: ElementRef;
  @ViewChild('gamePlayContainer', { static: false })
  gamePlayContainer: ElementRef;
  @ViewChild('gameWindow', { static: false }) gameWindow: ElementRef;

  gameData = [];
  paginationMultiplier = 1;
  gameDataSubscription: any;
  authSubscription: any;
  routeSubscription: any;
  authenticationSubscription: any;
  showFrame = false;
  userInfoLocalSubscription: any;
  userData: any;
  showErrorPage = false;
  gameDetails: any;
  formData: any;
  iwbBgImage = '';
  iframeHeight = '768px';
  iframeWidth = '1024px';
  addOnClass = 'fullScreenOff';
  winChild = [];
  deviceInfo: any;
  isMobile = false;
  isDesktopDevice = true;
  screenHeight = 0;
  screenWidth = 0;
  vendorId = '';
  gameId = '';
  playType = '';
  returnUrl = '';
  iframeSrc: any;
  vendorType = '';
  ipAddress = '';
  ipSubscription: any;
  tabs = ['Featured', 'New', 'ALL', 'Casino'];
  allowedDisplayType = [
    'Featured',
    'New',
    'ALL',
    'Casino',
    // 'Slots',
    // 'Table Game',
    // 'Live Dealer',
  ];
  filter = this.tabs[0];
  defaultImage = 'assets/images/default.gif';
  showSearchBox = false;
  searchText = '';
  showHeader = false;
  allGameData = {};
  pageType = 'GAMELIST';
  gameplay_url = '#';
  gameSessionSubscription: any;
  currentGameData: any;
  playerInfo: any;
  currTokenVal: any;
  webDomain: any;
  homeURL: any;
  isMobileDevice = false;
  sortCriteria = '';
  sortCriterias = {};

  constructor(
    //private router: Router,
    private routeValue: Router,
    private SlotService: SlotService,
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
    public loader: LoaderService,
    private ngxLoader: NgxUiLoaderService,
    private _ngSearchFilterService: NgSearchFilterService,
    private deviceService: DeviceDetectorService,
    private _location: Location,
    private renderer: Renderer2,
    private popupService: PopupService
  ) {
    this._ngSearchFilterService.setDefaultLang('en');
    // this.routeSubscription = router.events.pipe(
    //   filter(e => e instanceof ActivationEnd),
    //   first()
    // ).subscribe(val => {
    //
    //   let tempVar = <any>val;
    //   let params = tempVar.snapshot.params;
    //   var new_obj = Object.assign({"userName": ""}, params );
    //   this.userData = new_obj;
    //   if( this.userData.closegame == 'exit' ){
    //     window.close();
    //   }
    //   if( !this.userData.catagory ){
    //     this.userData.catagory = 'all';
    //   }
    //
    // });
    // this.playerInfo = await this.SlotService.getPlayerAccountInfo(this.userData.token);
    // this.isMobile = (this.deviceService.isMobile() || this.deviceService.isTablet());
    this.isMobile = false;
    this.isMobileDevice =
      this.deviceService.isMobile() || this.deviceService.isTablet();

    if (this.isMobileDevice) {
      this.renderer.addClass(document.body, 'mobile-device');
    }

    const orientation = window.orientation;
    if (orientation === 90 || orientation === -90) {
      this.isMobileDevice = false;
    }

    // this.isIphone = /iPhone/.test(navigator.userAgent);
    console.log('ATTACHING TO POST MESSAGES');
    window.onmessage = (msg) => {
      if (msg?.data?.name === 'gameQuit') {
        // window.location.href =  `${this.homeURL}/${this.currTokenVal}`;
        window.location.reload();
      }
    };
  }

  ngOnInit() {
    this.webDomain = environment[environment.client].webDomain;
    this.homeURL = environment[environment.client].homeURL;
    var tokenVal = window.location.href.split('/');
    if (tokenVal.length >= 3) {
      this.currTokenVal = tokenVal[3];
      this.userData = Object.assign({ siteToken: this.currTokenVal, info: '' });
    } else {
    }
    // this.routeValue.paramMap.subscribe(params => {
    //   const token = params.get('token');
    //   console.log('Token:', token);
    //
    //   // You can now use the 'token' variable in your component logic.
    // });

    // this.currTokenVal = this.routeValue.snapshot.paramMap.get('token');

    this.ngxLoader.start();

    this.authSubscription = this.SlotService.subscribeAuthUser()
      .pipe(
        delay(0),
        filter((response) => response != null)
      )
      .subscribe((response: any) => {
        this.ngxLoader.stop();
        // if (response.responseCode == 1000 || Object.keys(response.data.slot.engines).length == 0) {
        //   this.showErrorPage = true;
        //   return false;
        // }
        this.userData.info = response.data;
        // let tempRes = JSON.parse('{"success":true,"data":{"accountInfo":{"account":"VIP2223673","isTestAccount":false},"balanceInfo":{"balance":282.23,"currency":"USD"}}}');
        // this.userData.info = tempRes.data;
        this.SlotService.getGameList();
      });

    this.SlotService.authUserDetails(this.currTokenVal);
    this.gameDataSubscription = this.SlotService.subscribeGameList()
      .pipe(
        delay(0),
        filter((response) => response != null)
      )
      .subscribe((response: any) => {
        this.ngxLoader.stop();
        if (
          response.responseCode == 1000 ||
          Object.keys(response.data.slot.engines).length == 0
        ) {
          this.showErrorPage = true;
          return false;
        }
        this.ipAddress = response.data.ipAddress;
        this.gameData = [];
        for (const key in response.data.slot.engines) {
          if (key == 'GSOFT' || key == 'STA88') {
            this.sortCriterias = response.data.slot.engines[key].sortCriteria;
            this.sortCriteria = this.sortCriterias[this.filter];
            response.data.slot.engines[key].games.forEach((element1) => {
              element1.displayType = element1.gameCategory?.split(',');
              // .some(r=> arr2.includes(r))
              // this.userData.catagory = 'all';
              //if( val == this.userData.catagory || this.userData.catagory == 'all') {
              if (
                this.allowedDisplayType.some((r) =>
                  element1.displayType.includes(r)
                )
              ) {
                this.gameData.push({
                  order: element1.order || 0,
                  displayType: element1.displayType,
                  id: element1.gameId,
                  gameCode: element1.gameCode,
                  gameName: element1.gameName,
                  gameCategory: element1.gameCategory,
                  game: {
                    NAME: element1.gameName,
                    IMAGEURL:
                      element1.defaultImg || '/assets/images/banner1.jpg',
                    defaultRtp: element1.defaultRtp,
                    volatility: element1.volatility,
                    minBet: element1.minBet,
                    maxBet: element1.maxBet,
                  },
                  params: response.data.slot.engines[key].params,
                  vendor: key,
                });
              }

              // if (!this.tabs.includes(element1.displayType)) {
              //   if (this.allowedDisplayType.includes(element1.displayType)) {
              //     this.tabs.push(element1.displayType);
              //   }
              // }
              // }
            });
          }
        }
        // console.log(this.gameData);
        //this.gameData.sort( () => Math.random() - 0.5);
        // this.gameData.sort(function (a, b) {
        //   var keyA = a.order,
        //     keyB = b.order;
        //   // Compare the 2 dates
        //   if (keyA < keyB) return -1;
        //   if (keyA > keyB) return 1;
        //   return 0;
        // });

        if (this.gameData.length > 8) {
          this.showSearchBox = true;
          this.showHeader = true;
        }

        this.gameData.forEach((ele) => {
          this.allGameData[ele.vendor + '-' + ele.id] = ele;
        });

        // console.log(this.gameData);
        // console.log(this.allGameData);

        this.searchPlay();
        this.commonService.iframe_resize();
      });

    // this.commonService.iframe_resize();
  }

  getFullScreenButtonStyles() {
    // if(this.isIphone){
    //   return {
    //     'display':'none',
    //   }
    // }
    if (this.isMobileDevice) {
      return {
        cursor: 'pointer',
        'z-index': '99999',
        height: 'fit-content',
        width: '98%',
        'padding-top': '8px',
      };
    } else {
      return {
        cursor: 'pointer',
        'z-index': '99999',
        height: 'fit-content',
        width: '99%',
        'padding-top': '8px',
      };
    }
  }

  randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  searchPlay() {
    if (
      typeof this.vendorId != 'undefined' &&
      this.vendorId != '' &&
      typeof this.gameId != 'undefined' &&
      this.gameId != '' &&
      typeof this.playType != 'undefined' &&
      this.playType != ''
    ) {
      for (let i = 0; i < this.gameData.length; i++) {
        if (
          (this.gameData[i].game.gameNumber == this.gameId ||
            this.gameData[i].game.id == this.gameId) &&
          this.gameData[i].vendor == this.vendorId
        ) {
          this.play(i, this.gameId, '', '', {});
        }
      }
    }
  }

  generate_session(id, gamePlayId, data) {
    this.SlotService.getGameSession(
      gamePlayId,
      this.currTokenVal,
      this.userData.info.accountInfo.account,
      data.gameCode,
      data.vendor
    ).subscribe((response: any) => {
      console.log(response);
      let gameSessionData = response.data;
      // let tempSesResp = JSON.parse('{"success":true,"data":{"token":"3085_6a281b466a93dd9b74ee6e"}}');
      // let gameSessionData = tempSesResp.data;
      try {
        if (gameSessionData.token) {
          this.play(
            id,
            gamePlayId,
            gameSessionData.token,
            data,
            gameSessionData
          );
        } else {
          this.popupService.openPopup('Message', response.data.error);
          // alert(response.data.error);
          return false;
        }
      } catch (e) {
        alert(e);
        return false;
      }

      return true;
    });
  }

  backClicked() {
    // this._location.back();
    window.location.href = environment[environment.client].casinoUrl;
    // this.switchMode('GAMEPLAY');
    // this.pageType = "GAMEPLAY";
  }

  switchMode(type = '') {
    switch (type) {
      case 'GAMELIST':
        this.pageType = 'GAMELIST';
        setTimeout(() => {
          reloadSwiperSlider();
        }, 500);

        // try{
        //   new Swiper('.swiper.swiper-4', {
        //     loop: true,
        //     nextButton: '.swiper-button-next',
        //     prevButton: '.swiper-button-prev',
        //     slidesPerView: 3,
        //     paginationClickable: true,
        //     spaceBetween: 10,
        //     breakpoints: {
        //       1920: {
        //         slidesPerView: 3,
        //         spaceBetween: 10
        //       },
        //       1028: {
        //         slidesPerView: 2,
        //         spaceBetween: 10
        //       },
        //       480: {
        //         slidesPerView: 1,
        //         spaceBetween: 10
        //       }
        //     }
        //   });
        // }
        // catch (e) {
        //   console.log(e);
        // }

        break;
    }
  }

  play(id, gamePlayId, gamSession, data, gameSessionData) {
    let playType = '';
    if (this.userData.info.accountInfo.account && gamSession) {
      playType = 'real';
    }
    this.vendorId = '';
    this.gameId = '';
    // this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl('');
    // this.gameDetails = this.gameData[id];
    this.gameDetails = this.allGameData[id];
    if (!this.isMobile) {
      this.pageType = 'GAMEPLAY';
    }
    this.currentGameData = data;
    this['setHtmlData_' + this.gameDetails.params.vendorType](
      playType,
      gamePlayId,
      gamSession,
      gameSessionData
    );
    window.scroll(0, 0);
  }

  setUserPlayData(id, playType) {
    let userPlayData = {
      extra: {
        engine: Constants.IGE_ENGINE,
        path: '/ige/lobby',
        vendorId: this.gameData[id].vendor,
        gameId: this.gameData[id].game.gameNumber || this.gameData[id].game.id,
        playType: playType,
      },
    };
  }

  urlGenerator(formData) {
    let urlString = formData.path;
    if (['NG'].includes(this.vendorType)) {
      urlString += '?';
      let counter = 0;
      for (const key in formData) {
        if (['path', 'submissionType', 'target'].includes(key)) {
          continue;
        }
        if (counter != 0) {
          urlString += '&';
        }
        urlString += key + '=' + encodeURIComponent(formData[key]);
        ++counter;
      }
    } else if (['PS'].includes(this.vendorType)) {
      urlString += '?';
      let counter = 0;
      for (const key in formData) {
        if (['path', 'submissionType', 'target'].includes(key)) {
          continue;
        }
        if (counter != 0) {
          urlString += '&';
        }
        urlString += key + '=' + encodeURIComponent(formData[key]);
        ++counter;
      }
    } else if (['GSOFT'].includes(this.vendorType)) {
      urlString += '?';
      let counter = 0;
      for (const key in formData) {
        if (['path', 'submissionType', 'target'].includes(key)) {
          continue;
        }
        if (counter != 0) {
          urlString += '&';
        }
        urlString += key + '=' + encodeURIComponent(formData[key]);
        ++counter;
      }
    }
    return urlString;
  }

  setHtmlData_NG(playType) {
    this.vendorType = this.gameDetails.params.vendorType;
    if (playType == 'real') {
      this.formData = {
        path: this.gameDetails.params.root + this.gameDetails.params.repo,
        token: this.userData.token + '__' + this.userData.playerid,
        mode: 'real',
        gameId: this.gameDetails.game.ID,
        lang: 'en',
        bankId: this.gameDetails.params.bankId,
        target: this.isMobile
          ? 'gamewindow-' +
            this.gameDetails.params.vendorType +
            '-' +
            this.gameDetails.game.gameNumber +
            'Buy'
          : 'playScreen',
        // To delete later//
        submissionType: 'GET',
      };
    } else {
      this.formData = {
        path: this.gameDetails.params.root + this.gameDetails.params.repo,
        gameId: this.gameDetails.game.ID,
        lang: 'en',
        bankId: this.gameDetails.params.bankId,
        target: this.isMobile
          ? 'gamewindow-' +
            this.gameDetails.params.vendorType +
            '-' +
            this.gameDetails.game.gameNumber +
            'Buy'
          : 'playScreen',
        // To delete later//
        submissionType: 'GET',
      };
    }
    this.showFrame = false;
    let urlString = this.urlGenerator(this.formData);
    setTimeout(() => {
      if (!this.isMobile) {
        this.checkImage(this.gameDetails.game.gameNumber);
        // this.gameDetailsBgInner.nativeElement.offsetTop;
        // this.gameDetailsBgInner.nativeElement.scrollIntoView({ behavior: "smooth", block: 'center' });
      } else {
        this.winChild[
          'gamewindow-' +
            this.gameDetails.params.vendorType +
            '-' +
            this.gameDetails.game.gameNumber +
            'Buy'
        ] = window.open(
          urlString,
          'gamewindow-' +
            this.gameDetails.params.vendorType +
            '-' +
            this.gameDetails.game.gameNumber +
            'Buy',
          'location=0,toolbar=0,menubar=0,status=0,height=' +
            this.iframeHeight +
            ',width=' +
            this.iframeWidth
        );
      }
      this.commonService.getWindowDimensions();
      // this.myFormPost.nativeElement.submit();
      this.commonService.iframe_resize();
      const el = this.gameWindow.nativeElement;
      el.addEventListener('load', () => {
        console.log('GAME IFRAME loaded ', el);
        el.focus();
      });
    }, 0);
    this.commonService.iframe_resize();
  }

  setHtmlData_PS(playType) {
    this.vendorType = this.gameDetails.params.vendorType;

    this.formData = {
      path: this.gameDetails.params.root + this.gameDetails.params.repo,
      session: this.userData.token,
      player: this.userData.playerid,
      machine: this.gameDetails.id,
      lang: 'en-US',
      casino: this.gameDetails.params.casino,
      lobbyURL: this.gameDetails.params.lobbyURL,
      depositURL: this.gameDetails.params.depositURL,
      target: this.isMobile
        ? 'gamewindow-' +
          this.gameDetails.params.vendorType +
          '-' +
          this.gameDetails.id +
          'Buy'
        : 'playScreen',
      // To delete later//
      submissionType: 'GET',
    };

    this.showFrame = false;
    let urlString = this.urlGenerator(this.formData);
    setTimeout(() => {
      if (!this.isMobile) {
        this.checkImage(this.gameDetails.game.gameNumber);
        // this.gameDetailsBgInner.nativeElement.offsetTop;
        // this.gameDetailsBgInner.nativeElement.scrollIntoView({ behavior: "smooth", block: 'center' });
      } else {
        this.winChild[
          'gamewindow-' +
            this.gameDetails.params.vendorType +
            '-' +
            this.gameDetails.game.gameNumber +
            'Buy'
        ] = window.open(
          urlString,
          'gamewindow-' +
            this.gameDetails.params.vendorType +
            '-' +
            this.gameDetails.game.gameNumber +
            'Buy',
          'location=0,toolbar=0,menubar=0,status=0,height=' +
            this.iframeHeight +
            ',width=' +
            this.iframeWidth
        );
      }
      this.commonService.getWindowDimensions();
      // this.myFormPost.nativeElement.submit();
      this.commonService.iframe_resize();
      const el = this.gameWindow.nativeElement;
      el.addEventListener('load', () => {
        console.log('GAME IFRAME loaded ', el);
        el.focus();
      });
    }, 0);
    this.commonService.iframe_resize();
  }

  setHtmlData_GSOFT(playType, gamePlayId, gameSession) {
    this.vendorType = this.gameDetails.params.vendorType;

    this.formData = {
      path: this.gameDetails.params.root + this.gameDetails.params.repo,
      session: gameSession,
      player: this.userData?.info?.accountInfo?.account,
      machine: this.gameDetails.id,
      lang: 'en-US',
      casino: this.gameDetails.params.casino,
      lobbyURL: this.gameDetails.params.lobbyURL,
      depositURL: this.gameDetails.params.depositURL,
      target: this.isMobile
        ? 'gamewindow-' +
          this.gameDetails.params.vendorType +
          '-' +
          this.gameDetails.id +
          'Buy'
        : 'playScreen',
      // To delete later//
      submissionType: 'GET',
    };

    this.showFrame = true;
    let urlString = this.urlGenerator(this.formData);
    setTimeout(() => {
      this.gameplay_url =
        this.gameDetails.params.playPath +
        '?nogsgameid=' +
        gamePlayId +
        '&' +
        'nogsoperatorid=' +
        this.gameDetails.params.operator_id +
        '&' +
        'sessionid=' +
        gameSession +
        '&' +
        'nogscurrency=USD&' +
        'nogslang=en_us&' +
        'nogsmode=real&' +
        'accountid=' +
        this.userData?.info?.accountInfo?.account +
        '&' +
        'homeurl=' +
        environment[environment.client].homeURL +
        '&' +
        'country=' +
        this.gameDetails.params.country +
        '&' +
        'is_test_account=' +
        this.gameDetails.params.is_test_account +
        '&' +
        'license=' +
        this.gameDetails.params.license +
        '&' +
        'device_type=desktop';

      console.log(this.gameplay_url);

      if (!this.isMobile) {
        // this.checkImage(this.gameDetails.game.gameNumber);
        // this.gameDetailsBgInner.nativeElement.offsetTop;
        // this.gameDetailsBgInner.nativeElement.scrollIntoView({ behavior: "smooth", block: 'center' });
      } else {
        this.winChild[
          'gamewindow-' +
            this.gameDetails.params.vendorType +
            '-' +
            gamePlayId +
            'Buy'
        ] = window.open(
          this.gameplay_url,
          'gamewindow-' +
            this.gameDetails.params.vendorType +
            '-' +
            this.gameDetails.game.gameNumber +
            'Buy',
          'location=0,toolbar=0,menubar=0,status=0,height=' +
            this.iframeHeight +
            ',width=' +
            this.iframeWidth
        );
      }
      this.commonService.getWindowDimensions();
      // this.myFormPost.nativeElement.submit();
      this.commonService.iframe_resize();
      const el = this.gameWindow.nativeElement;
      el.addEventListener('load', () => {
        console.log('GAME IFRAME loaded ', el);
        el.focus();
      });
    }, 0);
    this.commonService.iframe_resize();
  }

  setHtmlData_STA88(playType, gamePlayId, gameSession, gameSessionData) {
    this.vendorType = this.gameDetails.params.vendorType;
    this.formData = {
      path: this.gameDetails.params.root + this.gameDetails.params.repo,
      session: gameSession,
      player: this.userData?.info?.accountInfo?.account,
      machine: this.gameDetails.id,
      lang: 'en-US',
      casino: this.gameDetails.params.casino,
      lobbyURL: `${this.homeURL}/${this.currTokenVal}`, // this.gameDetails.params.lobbyURL,
      depositURL: this.gameDetails.params.depositURL,
      target: this.isMobile
        ? 'gamewindow-' +
          this.gameDetails.params.vendorType +
          '-' +
          this.gameDetails.id +
          'Buy'
        : 'playScreen',
      // To delete later//
      submissionType: 'GET',
    };

    this.showFrame = true;
    let urlString = this.urlGenerator(this.formData);
    setTimeout(() => {
      this.gameplay_url =
        this.gameDetails.params.playPath +
        '?' +
        // "nogsgameid="+ gamePlayId +"&" +
        // "nogsoperatorid="+  this.gameDetails.params.operator_id +"&" +
        // "sessionid="+ gameSession +"&" +
        // "nogscurrency=USD&" +
        // "nogslang=en_us&" +
        // "nogsmode=real&" +
        // "accountid="+ this.userData?.info?.accountInfo?.account +"&" +
        // "homeurl="+ environment[environment.client].homeURL +"&" +
        // "country="+ this.gameDetails.params.country +"&" +
        // "is_test_account="+ this.gameDetails.params.is_test_account +"&" +
        // "license="+ this.gameDetails.params.license +"&" +
        // "device_type=desktop"+"&" +

        'sessId=' +
        gameSession +
        '&' +
        'gameId=' +
        this.gameDetails.gameCode +
        '&' +
        'server=' +
        this.gameDetails.params.server +
        '&' +
        'pn=' +
        this.gameDetails.params.pn +
        '&' +
        'userName=' +
        this.userData.info.accountInfo.account +
        '&' +
        'lang=' +
        this.gameDetails.params.lang +
        '&' +
        'operatorId=' +
        this.gameDetails.params.operatorId +
        '&' +
        'lobbyURL=' +
        this.gameDetails.params.lobbyURL +
        '&' +
        'accessPassword=' +
        gameSessionData.accessPassword;

      //         https://webtest.aggreg88.com/?accessPassword={hashResult}&gameId={Game}&server=https://api2
      // .aggreg88.com/&lang={lang}&sessId={token}&operatorId=default&pn={pn}&lobbyURL={lobbyUrl}&userName={userName}

      console.log(this.gameplay_url);

      if (!this.isMobile) {
        this.gameplay_url += '&host=Mobile';
        this.checkImage(this.gameDetails.game.gameNumber);
        // this.gameDetailsBgInner.nativeElement.offsetTop;
        // this.gameDetailsBgInner.nativeElement.scrollIntoView({ behavior: "smooth", block: 'center' });
      } else {
        // this.winChild['gamewindow-' + this.gameDetails.params.vendorType + '-' + this.gameDetails.game.gameNumber + 'Buy'] = window.open(urlString, 'gamewindow-' + this.gameDetails.params.vendorType + '-' + this.gameDetails.game.gameNumber + 'Buy', 'location=0,toolbar=0,menubar=0,status=0,height=' + this.iframeHeight + ',width=' + this.iframeWidth);
      }
      console.log(this.gameplay_url);
      this.commonService.getWindowDimensions();
      // this.myFormPost.nativeElement.submit();
      this.commonService.iframe_resize();
      const el = this.gameWindow.nativeElement;
      el.addEventListener('load', () => {
        console.log('GAME IFRAME loaded ', el);
        el.focus();
      });
    }, 0);
    this.commonService.iframe_resize();
  }

  checkImage(gameNumber) {
    let imgPath = '/assets/images/iwgBg/' + gameNumber + '.jpg';
    this.iwbBgImage = 'url("' + imgPath + '")';
    var img = new Image();
    img.onload = () => {
      this.iwbBgImage = 'url("' + imgPath + '")';
      this.commonService.iframe_resize();
    };
    img.onerror = () => {
      this.iwbBgImage = 'url("/assets/images/iwgBg/default.jpg")';
      this.commonService.iframe_resize();
    };
    img.src = imgPath;
    this.commonService.iframe_resize();
  }

  closeChildWindows() {
    for (const key in this.winChild) {
      this.winChild[key].close();
    }
    this.winChild = [];
    // this.commonService.updatePlayerBalance();
    this.commonService.iframe_resize();
  }

  changeFilter(filter) {
    this.filter = filter;
    this.searchText = '';
    this.sortCriteria = this.sortCriterias[filter];
    this.paginationMultiplier = 1;
  }

  isShowMoreVisible() {
    const lengthOfFilteredGames = this.gameData
      .filter(
        (pd) =>
          pd.displayType?.indexOf(this.filter) > -1 || this.filter === 'ALL'
      )
      .filter(
        (pd) =>
          pd.gameCategory
            ?.toLowerCase()
            .indexOf(this.searchText.toLowerCase()) > -1 ||
          pd.gameCode?.toLowerCase().indexOf(this.searchText.toLowerCase()) >
            -1 ||
          pd.id?.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 ||
          pd.gameName?.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
      ).length;

    return lengthOfFilteredGames > 30 * this.paginationMultiplier;
  }

  showMore() {
    this.paginationMultiplier = this.paginationMultiplier + 1;
  }

  toggleFullScreen() {
    const fsDoc = <FsDocument>document;
    const el = this.gamePlayDiv.nativeElement;
    const gameContainer = this.gamePlayContainer.nativeElement;

    if (gameContainer.classList.contains('fullscreen')) {
      gameContainer.classList.remove('fullscreen');
      this.addOnClass = 'fullScreenOff';
    } else {
      gameContainer.classList.add('fullscreen');
      this.addOnClass = 'fullScreenOn';
    }

    if (
      !(
        fsDoc.fullscreenElement ||
        fsDoc.mozFullScreenElement ||
        fsDoc.webkitFullscreenElement ||
        fsDoc.msFullscreenElement
      )
    ) {
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen((<any>Element).ALLOW_KEYBOARD_INPUT);
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen();
      } else if (el.webkitEnterFullscreen) {
        el.webkitEnterFullscreen();
      }
    } else {
      if (fsDoc.exitFullscreen) {
        fsDoc.exitFullscreen();
      } else if (fsDoc.webkitExitFullscreen) {
        fsDoc.webkitExitFullscreen();
      } else if (fsDoc.msExitFullscreen) {
        fsDoc.msExitFullscreen();
      } else if (fsDoc.mozCancelFullScreen) {
        fsDoc.mozCancelFullScreen();
      }
    }
  }

  ngOnDestroy() {
    if (typeof this.gameSessionSubscription != 'undefined') {
      this.gameSessionSubscription.unsubscribe();
    }
  }
}
