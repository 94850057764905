import { Routes, RouterModule } from '@angular/router';
import {AppComponent} from './app.component';
import { WebComponent} from "./web";
import { AuthGuard } from './_guards/index'



const appRoutes: Routes = [
  // { path: 'gamelobby/:playerid', component: AppComponent },
  // { path: 'gamelobby/:playerid/:token', component: AppComponent },
  // { path: 'gamelobby/:playerid/:token/:catagory', component: AppComponent },
  { path: ':token', component: AppComponent },
  { path: 'error-page', component: WebComponent },
  // { path: 'gamelobby', component: AppComponent },
  // { path: 'gamelobby/:closegame', component: AppComponent },
   { path: '**', component: WebComponent }
];

export const routing = RouterModule.forRoot(appRoutes);

