import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import {DataService, ServerUrl} from "../_helpers";
import { filter, first, delay, map } from 'rxjs/operators';
import { LoaderService } from "src/app/_services";
import {nodeNameForError} from "@angular/compiler-cli/src/ngtsc/util/src/typescript";

@Component({
  templateUrl: 'web.component.html',
  styles: [
    `
      body {
        background: none;
      }
    `
  ],
  encapsulation: ViewEncapsulation.None
})

export class WebComponent implements OnInit {

  routeSubscription: any;
  roomId: any;
  user: any;
  announcer: any;
  caller: any;
  skin: any;

  loaderName = "Play Bingo";
  loaderName2 = "Pre Order Cards";
  isLoading = false;

  broswerName = "";
  osName = "";

    constructor(
      private dataservice: DataService,
      private router: Router,
      private loaderService: LoaderService,
    ) {
      window.name = "lobby";
      const body1 = document.getElementsByTagName('body')[0];
      body1.classList.remove("bodyHide");
      this.routeSubscription = router.events.pipe(
        filter(e => e instanceof ActivationEnd),
        first()
      ).subscribe(val => {
        let tempVar = <any>val;
        let params = tempVar.snapshot.params;
        this.roomId = params.roomId;
        this.user = params.user == '-' ? '' : params.user;
        this.announcer = params.announcer ? params.announcer : 'anne';
        this.caller = params.caller ? params.caller : 'anne';
        this.skin = params.skin && params.skin != '-' ? params.skin : 'blue';
      });
    }

    ngOnInit() {
      let loaderOuterWrap = document.getElementsByClassName("loaderOuterWrap")[0] as HTMLElement;
      let fullScreenTriggerWrap = document.getElementsByClassName("fullScreenTriggerWrap")[0] as HTMLElement;

      let bodyClass = document.getElementsByTagName('body')[0];
      bodyClass.classList.forEach( val => {
        if( val.indexOf("br-") != -1 ){
          this.broswerName = val.replace("br-","");
        }
        if( val.indexOf("os-") != -1 ){
          this.osName = val.replace("os-","");
        }
      });

      console.log("Browser: "+ this.broswerName);
      console.log("Os: "+ this.osName);

      setTimeout(() => {
        fullScreenTriggerWrap.style.display = 'none';
        loaderOuterWrap.style.display = 'none';
      }, 1000);

    }

    openLobby(){
      //this.loaderService.show();
      this.loaderName = "Wait...";
      this.isLoading = true;

    }

  openPreOrderLobby(){
    //this.loaderService.show();
    this.loaderName2 = "Wait...";
    this.isLoading = true;

  }

}
