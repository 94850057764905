import { Pipe, PipeTransform } from "@angular/core";

@Pipe( {
name: 'orderBy'
} )
export class OrderByPipe implements PipeTransform {
transform( array: Array<any>, orderField: string, sortCriteria: string ): Array<string> {
  
  const sortArray = sortCriteria.split(",");
  // array.sort((a, b) => sortArray.indexOf(a) - sortArray.indexOf(b));

    return [...array.filter((a:any)=>sortArray.indexOf(a[orderField])>-1).sort( ( a: any, b: any ) => {
        let ae = a[ orderField ];
        let be = b[ orderField ];
        return sortArray.indexOf(ae) - sortArray.indexOf(be);
        // if ( ae == undefined && be == undefined ) return 0;
        // if ( ae == undefined && be != undefined ) return orderType ? 1 : -1;
        // if ( ae != undefined && be == undefined ) return orderType ? -1 : 1;
        // if ( ae == be ) return 0;
        // return orderType ? (ae.toString().toLowerCase() > be.toString().toLowerCase() ? -1 : 1) : (be.toString().toLowerCase() > ae.toString().toLowerCase() ? -1 : 1);
    } ), ...array.filter((a:any)=>sortArray.indexOf(a[orderField])<0)];
    // return array;
  }
}